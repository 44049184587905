import React, {useState, useEffect, useRef} from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import "../css/home.css";
import {Container, Col, Row, Nav} from "react-bootstrap";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{
                display : "flex",
                flexDirection : "column",
                alignItems : "flex-start",
                textAlign : "left",
                padding : 20,
                fontSize : "1.2em"
            }}
            {...other}
        >
            {props.children}
            Send your resume to renaldy@komunestudio.com

        </div>
    );
}

const vacancies = [
    {
        title : "Full Time Business and Commercial Development",
        description : <p>
            Kami mencari seseorang yang dapat membantu memasarkan produk-produk kami dan mencari klien baru.
            <br/><br/>
            Job Description :
            <ul>
                <li>Mencari dan bertemu dengan calon klien</li>
                <li>Menjaga hubungan baik antara perusahaan dan klien yang sudah ada</li>
                <li>Berinovasi dan mencari cara untuk bertemu dengan klien baru</li>
                <li>Berkontribusi dengan tim teknis untuk membuat produk</li>
                <li>Membuat presentasi dan proposal</li>
            </ul>
            <br/>
            Job Requirement :
            <ul>
                <li>Pengalaman di bidang marketing, bisnis, event, atau sejenis minimal 1 tahun</li>
                <li>Memiliki skill komunikasi yang kuat</li>
                <li>Bekerja 40 jam perminggu, Senin - Jumat</li>
                <li>Jujur, mudah dihubungi dan bertanggung jawab</li>
            </ul>
            <br/>
            Benefits :
            <ul>
                <li>Work From Home dan waktu bekerja fleksibel</li>
                <li>Gaji dan Bonus</li>
                <li>Uang transport dan entertainment</li>
            </ul>
        </p>
    },
    {
        title : "Full Time Fullstack Javascript Developer",
        description : <p>
            Kami mencari fullstack developer
            <br/><br/>
            Job Description :
            <ul>
                <li>Mengembangkan aplikasi web ataupun mobile</li>
                <li>Meeting internal maupun bersama klien</li>
            </ul>
            <br/>
            Job Requirement :
            <ul>
                <li>Pemahaman Web Development menggunakan Javascript, React, NodeJS, Express.</li>
                <li>Pemahaman Web Development menggunakan React Native</li>
                <li>Pengalaman menggunakan MySQL</li>
                <li>Pemahaman Web Development menggunakan angular/flutter (opsional)</li>
                <li>Bekerja 40 jam perminggu, Senin - Jumat</li>
                <li>Jujur, mudah dihubungi dan bertanggung jawab</li>
            </ul>
            <br/>
            Benefits :
            <ul>
                <li>Work From Home dan waktu bekerja fleksibel</li>
                <li>Gaji dan Bonus</li>
                <li>Training dan Mentorship</li>
            </ul>
        </p>
    },
    {
        title : "Full Time IT Administrator Staff",
        description : <p>
            Kami mencari staff IT yang pekerjaan utamanya adalah memasukan data-data ke sistem. Kami berharap Staff IT ini dapat menggunakan ilmu IT yang dimiliki untuk membuat script / program sederhana untuk mempercepat proses penginputan data.
            <br/><br/>
            Job Description :
            <ul>
                <li>Menggunakan kemampuan bahasa scripting untuk memasukan data</li>
                <li>Mengubah dan membuat tampilan situs front end sederhana</li>
                <li>Memasukan dan mengganti data pada sistem</li>
                <li>Meeting internal maupun bersama klien</li>
            </ul>
            <br/>
            Job Requirement :
            <ul>
                <li>Pengetahuan dasar untuk HTML5, CSS dan Javascript</li>
                <li>Lulusan SMK terkait IT, S1 IT, atau sejenis</li>
                <li>Pemahaman dasar untuk penggunaan terminal linux </li>
                <li>Pemahaman dasar untuk bahasa scripting seperti Javascript, Python, atau sejenis</li>
                <li>Mempunyai laptop / komputer yang bisa dipakai untuk bekerja</li>
                <li>Bekerja 40 jam perminggu, Senin - Jumat</li>
                <li>Jujur, mudah dihubungi dan bertanggung jawab</li>
            </ul>
            <br/>
            Benefits :
            <ul>
                <li>Work From Home dan waktu bekerja fleksibel</li>
                <li>Gaji dan Bonus</li>
                <li>Training dan Mentorship</li>
            </ul>
        </p>
    }
]

function JoinUs() {

    const [page,setPage] = useState(0)

    return (
        <div style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#3c3b3e",
        }}>
            <Container
                style={{
                    minHeight: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Row style={{width: "100%"}}>
                    <Col
                        md={12}
                        id="contact-left"
                        className="align-self-center"
                        style={{
                            fontSize: "1.2em",
                            display : "flex",
                            flexDirection : "column",
                            alignItems : "flex-start"
                        }}
                    >
                        <h1>
                            <span className="font-bold">komune studio</span>{" "}
                            <span className="font-thin">Software House</span>
                        </h1>
                        <h3 className="font-thin">CV. Solusi Indonesia Kreatif</h3>

                        <h4 className="font-thin">We are hiring for January 2023 Batch</h4>

                        <img
                            className="bg-contact"
                            alt="Komune Studio Logo"
                            src={require("./new-komune-logo-only.png")}
                        ></img>
                    </Col>
                    <Col
                        style={{
                            color : "white",
                            display : "flex",
                            textAlign : "left"
                        }}
                        md={12}>
                        <Tabs
                            style={{
                                display : "flex",
                                flexDirection : "column",
                                alignItems : "flex-start",
                            }}
                            orientation="vertical"
                            variant="scrollable"
                            value={page}
                            onChange={(e, value)=>{setPage(value)}}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                        >
                            {
                                vacancies.map((obj,key)=>{
                                    return <Tab
                                        style={{width : "100%", display:"flex",flexDirection:"column", alignItems : "flex-start"}}
                                        label={<div style={{textAlign : "left"}}>{obj.title}</div>} {...a11yProps(6)} />
                                })
                            }
                        </Tabs>

                        {
                            vacancies.map((obj,key)=>{
                                return  <TabPanel value={page} index={key}>
                                    {obj.description}
                                </TabPanel>
                            })
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default JoinUs;
