import React from 'react';
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { FaLongArrowAltRight } from 'react-icons/fa';
import {Col, Container, Row} from "react-bootstrap";
import "../css/aboutUs1.css";

function AboutUs(){
    const visibilityContext = React.useContext(VisibilityContext);

    const nextPage = () => {
        visibilityContext.scrollNext()
    }

    return (
        <section style={{backgroundColor: "#FFFEF9"}}>
            <div className='aboutUs1'>
                <Container className="container-aboutUs1">
                    <Row>
                        <Col md={8} sm={12} className="left-container">
                            <div id="about-left" style={{ textAlign: "left" }}>
                                <div>
                                    <div className="textDesktop-1">
                                        Founded in 2019, Komune Studio is a digital technology agency
                                        <br />
                                        aimed to provide cloud based solution for our client needs.
                                        <br />
                                    </div>

                                    <br />

                                    <div className="textDesktop-2">
                                        We have seen how tech industries in Indonesia has been growing
                                        <br />
                                        diversely and decided to contribute as much as we could. We
                                        <br />
                                        always seek for new fun and challenging project, especially when
                                        <br />
                                        it comes to emerging technology in Indonesia.
                                        <div className="mt-4"><a onClick={nextPage} className="more-btn"
                                                style={{cursor: "pointer", color: "#FEBE10", gap: "8px", display: "flex", alignItems: "center"}}><FaLongArrowAltRight className="long-arrow"/> MORE</a>
                                            </div>
                                    </div>

                                    <div className="textMobile-1">
                                        <div>
                                            Founded in 2019, Komune Studio is a digital
                                            <br />
                                            technology agency aimed to provide cloud based 
                                            <br />
                                            solution for our client needs.
                                            <br />
                                        </div>
                                    </div>

                                    <div className="about-middle">
                                        <img src="mockup-desktop-mobile.png" alt="desktop-mobile"></img>
                                    </div>

                                    <div className="textMobile-2">
                                        <div>
                                            We have seen how tech industries in Indonesia has
                                            <br />
                                            been growing diversely and decided to contribute as 
                                            <br />
                                            much as we could. We always seek for new fun and 
                                            <br />
                                            challenging project, especially when it comes to 
                                            <br />
                                            emerging technology in Indonesia.
                                            <div className="mt-4"><a onClick={nextPage} className="more-btn"
                                                style={{cursor: "pointer", color: "#FEBE10", gap: "8px", display: "flex", alignItems: "center"}}><FaLongArrowAltRight className="long-arrow"/> MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                
                                </div>
                            </div>
                        </Col>

                        <Col md={4}>   
                            <div className="about-right">
                                <img src="mockup-desktop-mobile.png" alt="desktop-mobile"></img>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    )
}

export default AboutUs;
