import React, {useEffect, useRef, useState} from "react";
import Form from 'react-bootstrap/Form';
import {useParams} from 'react-router-dom';
import Contact from '../dao/contact'
import {Container, Row, Col, Button} from "react-bootstrap";

import Swal from "sweetalert2"

let requestCountDownTime = 0

export default function RequestDelete(props) {

    let {app} = useParams();

    const [email, setEmail] = useState("")
    const [deleteReason, setDeleteReason] = useState("")

    const resetForm = () => {
        setEmail("")
        setDeleteReason("")
    }

    return <Container
        style={{
            paddingTop: 20,
            textAlign: "left"
        }}
    >
        <Row>
            <Col>
                <h2>Account Delete Form</h2>
                <Form.Label>Hi, we are sorry to hear that you are going to remove your account from <b>{app}</b> Please
                    fill in the form below to confirm the deletion </Form.Label>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control onChange={(e) => {
                            setEmail(e.target.value)
                        }} type="email" placeholder="Please enter your email"/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Delete Reason</Form.Label>
                        <Form.Control onChange={(e) => {
                            setDeleteReason(e.target.value)
                        }} as="textarea" placeholder="Please enter the reason to delete your account"
                                      rows={3}/>
                    </Form.Group>
                </Form>
                <Button
                    onClick={async () => {
                        let result = await Swal.fire({
                            title: "Are you sure",
                            text: "Are you sure you want to delete your account?",
                            icon: "info",
                            showCancelButton: true,
                            cancelButtonText: "No",
                            confirmButtonText: "Yes",
                        })
                        if (result.isConfirmed) {
                            try {
                                const values = {
                                    app_name: app,
                                    email: email,
                                    subject: `[${app}] DELETE APP - ${email}`,
                                    message: deleteReason
                                }
                                console.log('delete reason', deleteReason)
                                let res = await Contact.sendContact(values)
                                console.log('isi res', res)
                                Swal.fire({
                                    title: "",
                                    text: "Your query is submitted, your request will be processed within 4-5 business days.",
                                    icon: "success",
                                })
                                resetForm()
                                return false
                            } catch (e) {
                                console.log(e)
                                Swal.fire({
                                    title: "",
                                    text: "There was error on our server, please try again",
                                    icon: "error",
                                })
                                return false
                            }

                        }
                    }}
                    style={{color: "white"}}>Submit</Button>
            </Col>
        </Row>

    </Container>

}
