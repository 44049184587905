import React, { useState, useEffect, useRef } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

import Contact from "./contact";
import AboutUs from "./aboutUs";
import AboutUs2 from "./aboutUs2";
import Demo from "./demo";
import Nav from "../components/nav";
import "../css/home.css";
import { Container, Col, Row } from "react-bootstrap";
import DOTS from "vanta/dist/vanta.dots.min";
import WAVES from "vanta/dist/vanta.waves.min";
import GLOBE from "vanta/dist/vanta.globe.min";
import { useSwipeable } from "react-swipeable"

function Home() {
  // To get the viewport height
  const viewportHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };
  viewportHeight();

  // used when the user rotates the device or resize
  window.addEventListener("resize", viewportHeight);

  // scroll to next page
  const handleOnWheel = (VisibilityContext, event) => {
    const isTouchpad =
      Math.abs(event.deltaX) !== 0 || Math.abs(event.deltaY) < 15;

    if (isTouchpad) {
      event.stopPropagation();
      return;
    }

    if (event.deltaY < 0) {
      VisibilityContext.scrollPrev();
    } else if (event.deltaY > 0) {
      VisibilityContext.scrollNext();
    }
  };

  // handle mobile swipe gesture
  const apiRef = React.useRef({});
  const swipeConfig = {
    delta: 10,                             // min distance(px) before a swipe starts.
    preventScrollOnSwipe: true,            // prevents scroll during swipe 
    trackTouch: true,                      // track touch input
    trackMouse: false,                     // track mouse input
    rotationAngle: 0,                      // set a rotation angle
    swipeDuration: Infinity,               // allowable duration of a swipe (ms).
    touchEventOptions: { passive: true },  // options for touch listeners
  }

  const swipeHandlers = useSwipeable({
    onSwipedRight: (eventData) => {
      apiRef.current.scrollPrev();
    },
    onSwipedLeft: (eventData) => {
      apiRef.current.scrollNext();
    },
    ...swipeConfig,
  });

  const HomePage = () => {
    const [vantaEffect, setVantaEffect] = useState(0);
    const vantaRef = useRef(null);

    useEffect(() => {
      if (!vantaEffect) {
        setVantaEffect(
          /*
          DOTS({
            el: vantaRef.current,
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.0,
            minWidth: 200.0,
            scale: 1.0,
            scaleMobile: 1.0,
            color: 0xfebe10,
            color2: 0x3c3b3e,
            backgroundColor: 0x3c3b3e,
          })
          
         /*
          VANTA.WAVES({
            el: vantaRef.current,
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.00,
            minWidth: 200.00,
            scale: 1.00,
            scaleMobile: 1.00,
            color: 0x3c3b3e,
            shininess: 0.00
          })
          */
          VANTA.GLOBE({
            el: vantaRef.current,
            mouseControls: true,
            touchControls: true,
            gyroControls: true,
            minHeight: 200.0,
            minWidth: 200.0,
            scale: 1.0,
            scaleMobile: 1.0,
            color: 0xfebe10,
            color2: 0xfffef9,
            backgroundColor: 0x3c3b3e,
            size: 0.8
          })
        );
      }
      return () => {
        if (vantaEffect) vantaEffect.destroy();
      };
    }, [vantaEffect]);

    /*
    <img
                  className="komune-logo-only"
                  src={require("../images/komune-logo-only.png")}
                />
                */

    return (
      <div id="home" ref={vantaRef}>
        <Container
          fluid
          className="container-home d-flex justify-content-center"
        >
          <div className="home-content-container">
            <div className="home-content">
              <div className="name mb-1">
                <span className="font-semi-bold">komune studio</span> Software
                House
              </div>
              <p className="tagline font-medium">
                WE PROVIDE DIGITAL SOLUTION FOR YOUR NEEDS
              </p>
            </div>
          </div>
        </Container>
      </div>
    );
  };

  // <Contact itemId="contact" />
  return (
    <div {...swipeHandlers}>
      <script src="three.r119.min.js"></script>
      <script src="vanta.dots.min.js"></script>
      <ScrollMenu
        apiRef={apiRef}
        onWheel={handleOnWheel}
        scrollContainerClassName="scroll-container"
      // transitionDuration={3000}
      >
        <HomePage itemId="home" />
        <AboutUs itemId="aboutUs" />
        <AboutUs2 itemId="aboutUs2" />
        <Demo itemId="demo" />
        <Contact itemId="contact" />
        <Nav />
      </ScrollMenu>
      <a href="https://wa.me/6281383798649">
        <div style={{
          cursor: "pointer",
          position: "fixed",
          zIndex: 99999,
          bottom: "3vh",
          right: "25px",
          backgroundColor: "rgb(38, 211, 103)",
          width: "50px",
          height: "48px",
          borderRadius: "5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "rgb(136 136 136) 0px 0px 3px 1px",
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--ic" width="1em" height="1em" viewBox="0 0 24 24" style={{color: "white"}}>
            <path fill="currentColor" d="M19.05 4.91A9.816 9.816 0 0 0 12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01zm-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18l-3.12.82l.83-3.04l-.2-.31a8.264 8.264 0 0 1-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24c2.2 0 4.27.86 5.82 2.42a8.183 8.183 0 0 1 2.41 5.83c.02 4.54-3.68 8.23-8.22 8.23zm4.52-6.16c-.25-.12-1.47-.72-1.69-.81c-.23-.08-.39-.12-.56.12c-.17.25-.64.81-.78.97c-.14.17-.29.19-.54.06c-.25-.12-1.05-.39-1.99-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.14-.25-.02-.38.11-.51c.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31c-.22.25-.86.85-.86 2.07c0 1.22.89 2.4 1.01 2.56c.12.17 1.75 2.67 4.23 3.74c.59.26 1.05.41 1.41.52c.59.19 1.13.16 1.56.1c.48-.07 1.47-.6 1.67-1.18c.21-.58.21-1.07.14-1.18s-.22-.16-.47-.28z" />
          </svg>
        </div>
      </a>
    </div>
  );
}

export default Home;
