import logo from './logo.svg';


import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import {BrowserRouter, HashRouter, Route, Routes} from "react-router-dom"
import Home from './pages/home';
import JoinUs from "./pages/joinUs";
import RequestDelete from "./pages/requestDelete";


function App() {
  return (
    <div className="App">
        <BrowserRouter basename="/">
            <Routes>
                <Route path= "/join-us" element={<JoinUs/>}/>
                <Route path= "/request-delete/:app" element={<RequestDelete/>}/>
                <Route path= "*" element={<Home/>}/>
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
