import React from 'react';
import {FaCogs} from 'react-icons/fa';
import {FaDesktop} from 'react-icons/fa';
import {FaRegChartBar} from 'react-icons/fa';
import {FaLongArrowAltRight} from 'react-icons/fa';
import {VisibilityContext} from "react-horizontal-scrolling-menu";
import {Col, Container, Row} from "react-bootstrap";
import "../css/aboutUs2.css";




function AboutUs2() {
  const visibilityContext = React.useContext(VisibilityContext);

  const nextPage = () => {
    visibilityContext.scrollNext()
  }

  return (
    <section style={{backgroundColor: "#FFFEF9"}}>
      <div style={{width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center",}}>
        <Container className="container-aboutUs2">
          <Row>
            <Col md={12}>
              <div id="about-upper" style={{textAlign: "left"}}>
                <p style={{fontSize: "32px", color: "#FEBE10",}}>
                  <span className="services-we-offer-text" style={{fontWeight: "bolder"}}>Services We Offer</span>
                </p>

                <div style={{fontWeight: "normal", fontSize: "24px"}}>
                  <div className="textDesktop">
                    Our development services is divided into two streamlines, <i><b>system integration</b></i> and
                    <br/>
                    <i><b>web development services.</b></i>
                  </div>

                  <div className="textMobile">
                    Our development services is divided into 
                    <br />
                    two streamlines, <i><b>system integration</b></i> and
                    <br />
                    <i><b>web development services.</b></i>
                  </div>
                  
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              md={4}>
              <div style={{textAlign : "left"}}>
                <div className="system-title" style={{fontWeight: "bolder", gap: "10px", display: "flex", alignItems: "center", color: "#183661"}}><FaCogs color='#000000'/> System Integration</div>
                <div className="desc-desktop">
                  <p className="system-integration-text">
                    We provide personalised integration for
                    <br/>
                    your system based on the inquiry.
                    <br/>    
                    Commonly integrating sofware to
                    <br/>      
                    communicate with hardware or vice
                    <br/>
                    versa.
                  </p>
                </div>
                <div className="desc-mobile">
                <p className="system-integration-text">
                    We provide personalised integration for your system based 
                    <br/> 
                    on the inquiry. Commonly integrating sofware to
                    <br/>      
                    communicate with hardware or vice versa.
                  </p>
                </div>
              </div>
            </Col>
            <Col
              md={4}>
              <div style={{textAlign : "left"}}>
                <div className="web-title" style={{fontWeight: "bolder", gap: "10px", display: "flex", alignItems: "center", color: "#183661"}}><FaDesktop color='#000000'/> Web/Mobile App Development</div>
                <div className="desc-desktop">
                  <p className="web-development-text">
                    Any web based or mobile apps
                    <br/>
                    development, either it is static or dynamic
                    <br/>
                    website, we do it all. Based on the
                    <br/>
                    purposes itself.
                  </p>
                </div>
                <div className="desc-mobile">
                  <p className="web-development-text">
                    Any web based or mobile apps development, either it is 
                    <br/>
                    static or dynamic website, we do it all. Based on the
                    <br/>
                    purposes itself.
                  </p>
                </div>
              </div>
            </Col>
            <Col
              md={4}>
              <div style={{textAlign : "left"}}>
                <div className="dashboard-title" style={{fontWeight: "bolder", gap: "10px", display: "flex", alignItems: "center", color: "#183661"}}><FaRegChartBar color='#000000'/> Dashboard Development</div>
                <div className="desc-desktop">
                  <p className="dashboard-development-text">
                    Dashboard mainly used to customize and
                    <br/>
                    maintain the web or mobile app. You
                    <br/>
                    could evaluate data used to improve your
                    <br/>
                    business.
                  </p>
                </div>
                <div className="desc-mobile">
                  <p className="dashboard-development-text">
                    Dashboard mainly used to customize and
                    maintain the web 
                    <br/>
                    or mobile app. You
                    could evaluate data used to improve your
                    <br/>
                    business.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} style={{textAlign : "left"}}>
              <div>
                <br/>
                <div className="arrowDemo"><a onClick={nextPage}
                        style={{cursor: "pointer", fontSize: "24px", color: "#FEBE10", gap: "8px", display: "flex", alignItems: "center"}}><FaLongArrowAltRight size={30}/> DEMO</a>
                </div>
              </div>
            </Col>

          </Row>
        </Container>
      </div>
    </section>
  )
}

export default AboutUs2;
