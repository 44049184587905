import React from 'react';
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { FaLongArrowAltRight } from 'react-icons/fa';
import "../css/demo.css"
import {Conatiner, Row, Col, Container} from "react-bootstrap"

function Demo(){
    const visibilityContext = React.useContext(VisibilityContext);

    const scrollToContactUs = () => {
        visibilityContext.scrollNext();
    }

    return (
        <div id="demo">
            <Container className="container-demo d-flex flex-column align-items-center justify-content-center">
                <Row className='w-100'>
                    <h1 className='demo-title font-semi-bold'>Demo</h1>
                    <Col sm={6} className="left d-flex flex-column align-items-start">
                        <iframe className="video-demo mb-3" src="https://www.youtube.com/embed/Ngpi0lz27mQ" title="Virtual Event Demo - Learning Festival 2022 // Komune Studio Software House Unity Development" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <div className="bottom-text">
                            <p className="email m-0">Hit us up on <span className='font-semi-bold'>komunestudio@gmail.com</span> for detailed info.</p>
                            <p className="text-start mb-3">More interactive web based demo:</p>

                            <div className="logos mt-2 mb-2">
                                <a href="https://learningfest2022.intellivent.id/" target="_blank"><img alt="learningFest" className="learning-fest-logo" src={require("../images/learningfest.png")}/></a>
                                <a href="https://telin-demo.intellivent.id/#/" target="_blank"><img alt="telin" className="telin-logo" src={require("../images/telin.png")}/></a>
                            </div>

                            <p className="use-google-text m-0">*use the desktop version of Google chrome for best experience</p>
                        </div>
                    </Col>
                    <Col sm={6} className="right d-flex flex-column justify-content-center align-items-end">
                        <p className="contact-mobile" onClick={scrollToContactUs}><FaLongArrowAltRight className="long-arrow-contact"/>CONTACT US</p>
                        <div className="clients">
                            <a href="https://www.nodeflux.io/" target="_blank"><div className='grid-item'><img className='client-logo' alt="nodeflux-logo" src={require('../images/client-nodeflux.png')} /></div></a>
                            <a href="https://www.patjarmerah.com/" target="_blank"><div className='grid-item'><img className='client-logo' alt="patjar-logo" src={require('../images/client-patjar.png')} /></div></a>
                            <a href="https://sgu.ac.id/" target="_blank"><div className='grid-item'><img className='client-logo' alt="sgu-logo" src={require('../images/client-sgu.png')} /></div></a>
                            <a href="https://www.lionandlion.com/" target="_blank"><div className='grid-item'><img className='client-logo' alt="lionlion-logo" src={require('../images/client-lionlion.png')} /></div></a>
                            <a href="https://www.loreal-paris.co.id/" target="_blank"><div className='grid-item'><img className='client-logo' alt="loreal-logo" src={require('../images/client-loreal.png')} /></div></a>
                            <a href="" target="_blank"><div className='grid-item'><img className='client-logo' alt="maybelline-logo" src={require('../images/client-maybelline.png')} /></div></a>
                            <a href="https://garnier.co.id/" target="_blank"><div className='grid-item'><img className='client-logo' alt="garnier-logo" src={require('../images/client-garnier.png')} /></div></a>
                            <a href="https://www.larocheposay.co.id/" target="_blank"><div className='grid-item'><img className='client-logo' alt="larocheposay-logo" src={require('../images/client-larocheposay.png')} /></div></a>
                            <a href="" target="_blank"><div className='grid-item'><img className='client-logo pertamina' alt="pertamina-logo"  src={require('../images/client-pertamina.png')} /></div></a>
                            <a href="" target="_blank"><div className='grid-item'><img className='client-logo infomedia' alt="infomedia-logo"  src={require('../images/client-infomedia.png')} /></div></a>
                            <a href="https://telin-demo.intellivent.id/#/" target="_blank"><div className='grid-item'><img className='client-logo telin' alt="telin-logo"  src={require('../images/client-telin.png')} /></div></a>
                            <a href="https://www.primeskills.id/" target="_blank"><div className='grid-item'><img className='client-logo' alt="primeskills-logo"  src={require('../images/client-primeskills.png')} /></div></a>
                        </div>
                        <p className="contact-desktop" onClick={scrollToContactUs}><FaLongArrowAltRight className="long-arrow-contact"/>CONTACT US</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Demo;
