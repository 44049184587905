import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../css/contact.css";

function Contact() {
  return (
    <section>
      <Container fluid>
        <Row id="items-row" className="justify-content-sm-center" lg={12}>
          <Col
            id="contact-left"
            className="align-self-center"
            lg={4}
            md={8}
            xs={10}
          >
            <h1>
              <Col className="contact-title">
                <span className="font-bold" sm={10}>komune studio</span>{" "}
                <span className="font-thin" sm={10}>Software House</span>
              </Col>
            </h1>
            <h3 className="font-thin">CV. Solusi Indonesia Kreatif</h3>
            <p className="font-thin">
              <a href="https://wa.me/6281383798649" target="_blank" id="no-blue">+62 813 8379 8649</a>
              <br />
              <a href="mailto:komunestudio@gmail.com" id="no-blue">komunestudio@gmail.com</a>
              <br />
              <a href="https://www.instagram.com/komunestudio/" target="_blank" id="no-blue">@komunestudio</a>
              <br />
              <br />
              Find Us at:
              <br />
              <a href="https://goo.gl/maps/Qd1REcVJazq83fUt7" target="_blank" id="no-blue">
                Ruko Aniva Grande GC No. 19
                <br />
                Paramount Land, Tangerang
                <br />
                Banten 15334, Indonesia
              </a>
            </p>
            <img
              className="bg-contact"
              alt="Komune Studio Logo"
              src={require("./new-komune-logo-only.png")}
            ></img>
          </Col>
          <Col lg={4} md={12} sm={12} id="contact-right">
            <div>
              <iframe
                className="map-responsive"
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.926557989807!2d106.61932019999999!3d-6.2733875999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fd0822705115%3A0xbf34e93330144004!2sKomune%20Studio%20Software%20House!5e0!3m2!1sen!2sid!4v1683178381121!5m2!1sen!2sid"
                allowFullScreen
                frameBorder="0"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Contact;