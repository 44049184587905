import React from "react";
import "../css/nav.css"
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import {Container, Row, Col} from "react-bootstrap"

function Nav() {
    const visibilityContext = React.useContext(VisibilityContext);

    const changeActiveBtn = (btn) => {
        const navLinkActive = document.querySelector(".nav-link-active");
        const btnRect = btn.getBoundingClientRect();
        const navLinks = document.querySelector(".nav-links")
        const navLinksRect = navLinks.getBoundingClientRect();
        const width = btnRect.width;
        const left = btnRect.left - navLinksRect.left;

        navLinkActive.style.width = width + "px";
        navLinkActive.style.left = left + "px";
        
        // const navLinks = document.querySelectorAll(".nav-links > .nav-link")
        // navLinks.forEach(link => {
        //     link.classList.remove("nav-link-active")
        // })

        // btn.classList.add("nav-link-active");
    }   

    const handleNavClick = (event) => {
        const scrollTargetId = event.target.name;        
        const scrollTarget = visibilityContext.getItemElementById(scrollTargetId);
        
        visibilityContext.scrollToItem(scrollTarget);
    }

    const visibilityPercentage = (element) => {
        const viewportWidth = window.innerWidth;
        const scrollLeft = visibilityContext.scrollContainer.current.scrollLeft;
        const elementOffsetLeft = element.offsetLeft;
        const elementWidth = element.offsetWidth;

        const distance = scrollLeft + viewportWidth - elementOffsetLeft;
        const percentage = Math.round(distance/((viewportWidth + elementWidth) / 100))

        // 50 is visible, 0 and 100 is not visible
        return Math.min(100, Math.max(0, percentage))
    }

    const changeNavColor = (element, color, direction) => {
        const lines = document.getElementsByClassName("line")
        const navLinks = document.getElementsByClassName("nav-link")
        let mode;
        
        if(color === "dark"){
            mode = "add";
        }
        else if(color === "light"){
            mode = "remove";
        }

        if(direction === "rightToLeft"){
            if(element >= 7 && element < 100){
                lines[4].classList[mode]("dark");
                if(element >= 15){
                    navLinks[3].classList[mode]("dark");
                }
                if(element >= 18){
                    lines[3].classList[mode]("dark");
                }
                if(element >= 21){
                    navLinks[2].classList[mode]("dark");
                }
                if(element >= 25){
                    lines[2].classList[mode]("dark");
                }
                if(element >= 28){
                    navLinks[1].classList[mode]("dark");
                }
                if(element >= 32){
                    lines[1].classList[mode]("dark");
                }
                if(element >= 36){
                    navLinks[0].classList[mode]("dark");
                }
                if(element >= 44){
                    lines[0].classList[mode]("dark");
                }
            }
        }
        else if(direction === "leftToRight"){
            if(element <= 93){
                lines[0].classList[mode]("dark");
                if(element <= 86){
                    navLinks[0].classList[mode]("dark");
                }
                if(element <= 83){
                    lines[1].classList[mode]("dark");
                }
                if(element <= 78){
                    navLinks[1].classList[mode]("dark");
                }
                if(element <= 75){
                    lines[2].classList[mode]("dark");
                }
                if(element <= 72){
                    navLinks[2].classList[mode]("dark");
                }
                if(element <= 68){
                    lines[3].classList[mode]("dark");
                }
                if(element <= 65){
                    navLinks[3].classList[mode]("dark");
                }
                if(element <= 57){
                    lines[4].classList[mode]("dark");
                }
            }
        }
        else{
            for(let i = 0; i < lines.length; i++){
                lines[i].classList.add("dark");
            }
            for(let i = 0; i < navLinks.length; i++){
                navLinks[i].classList.add("dark");
            }
        }
    }

    let lastScrollLocation = 0;
    const handleScroll = (event) => {
        const homeBtn = document.getElementById("homeLink");
        const aboutUsBtn = document.getElementById("aboutUsLink");
        const demoBtn = document.getElementById("demoLink");
        const contactBtn = document.getElementById("contactLink")
        const homeVisibility = visibilityPercentage(visibilityContext.getItemElementById("home"));
        const demoVisibility = visibilityPercentage(visibilityContext.getItemElementById("demo"));
        const aboutUsVisibility = visibilityPercentage(visibilityContext.getItemElementById("aboutUs"));
        const aboutUs2Visibility = visibilityPercentage(visibilityContext.getItemElementById("aboutUs2"));
        const contactVisibility = visibilityPercentage(visibilityContext.getItemElementById("contact"));

        //scrolling to the left
        if(lastScrollLocation > visibilityContext.scrollContainer.current.scrollLeft){
            if(contactVisibility < 100 && demoVisibility == 100){
                changeNavColor(contactVisibility, "light", "leftToRight")
                changeActiveBtn(contactBtn);
            }
            else if(demoVisibility  < 100 && aboutUs2Visibility == 100){
                changeNavColor(demoVisibility, "dark", "leftToRight")
                changeActiveBtn(demoBtn);
            }
            else if(aboutUs2Visibility < 100 && homeVisibility == 100){
                changeNavColor(aboutUsVisibility, "dark", "none")
                changeActiveBtn(aboutUsBtn);
            }
            else if(homeVisibility < 100){
                changeNavColor(homeVisibility, "light", "leftToRight")
                changeActiveBtn(homeBtn);
            }
        }
        //scrolling to the right
        else if(lastScrollLocation !== 0 && lastScrollLocation < visibilityContext.scrollContainer.current.scrollLeft){
            if(homeVisibility > 0 && aboutUsVisibility == 0){
                changeNavColor(homeVisibility, "light", "leftToRight")
                changeActiveBtn(homeBtn);
            }
            else if(aboutUsVisibility > 0 && demoVisibility == 0){
                changeNavColor(aboutUsVisibility, "dark", "rightToLeft")
                changeActiveBtn(aboutUsBtn);
            }
            else if(demoVisibility > 0 && contactVisibility == 0){
                changeNavColor(demoVisibility, "dark", "leftToRight")
                changeActiveBtn(demoBtn);
            }
            else if(contactVisibility > 0){
                changeNavColor(contactVisibility, "light", "rightToLeft")
                changeActiveBtn(contactBtn);
            }
        }
        lastScrollLocation = visibilityContext.scrollContainer.current.scrollLeft
    }

   React.useEffect(() => {
        const homeBtn = document.getElementById("homeLink");
        // to show home is active in the first load
        changeActiveBtn(homeBtn)

        //scroll listener for the scrolling menu
        visibilityContext.scrollContainer.current.addEventListener("scroll", handleScroll)

        //resize listener for the window
        window.addEventListener("resize", () => {
            visibilityContext.scrollToItem(visibilityContext.getItemById("home"))
        })
   }, [])
    
    return(
        <div className="nav">
            <Row className="nav-content w-100">
                <Col className="line-container"><div className="line"></div></Col>
                <Col >
                    <div className="nav-links">
                        <a id="homeLink" name="home" className="nav-link" onClick={handleNavClick}>Home</a>
                        <div className="line"></div>
                        <a id="aboutUsLink" name="aboutUs" className="nav-link" onClick={handleNavClick}>About Us</a>
                        <div className="line"></div>
                        <a id="demoLink" name="demo" className="nav-link" onClick={handleNavClick}>Portfolio</a>
                        <div className="line"></div>
                        <a id="contactLink" name="contact" className="nav-link" onClick={handleNavClick}>Contact</a>
                        <div className="nav-link-active"></div>
                    </div>
                </Col>
                <Col className="line-container"><div className="line"></div></Col>
            </Row>
        </div>
    )
}

export default Nav